
import { computed } from '@vue/reactivity';
import { Input } from 'ant-design-vue';
import { SizeType } from 'ant-design-vue/es/config-provider';
import { useField } from 'vee-validate';
import { defineComponent, PropType } from 'vue';
export default defineComponent({
  components: {
    Input
  },
  props: {
    type: {
      type: String,
      default: 'text'
    },
    value: {
      type: [String, Number],
      default: ''
    },
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    rules: {
      type: Object,
      default: undefined
    },
    size: {
      type: String as PropType<SizeType>,
      default: 'large'
    }
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup(props: any, { emit }: { emit: any }) {
    const {
      value: fieldValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
      errors
    } = useField(props.name, props.rules, {
      initialValue: props.value
    });
    const inputValue = computed({
      get: () => {
        return fieldValue.value;
      },
      set: (value) => {
        emit('update:value', value);
      }
    });
    return {
      handleChange,
      handleBlur,
      errorMessage,
      inputValue,
      meta,
      errors
    };
  }
});

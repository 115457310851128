import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = { class: "w-full relative input-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Input = _resolveComponent("Input")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["text-left flex items-center", { 'mb-10': _ctx.errorMessage }])
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.name,
          class: "input-label text-black font-semibold"
        }, _toDisplayString(_ctx.label), 9, _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Input, {
        id: _ctx.name,
        value: _ctx.inputValue,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
        name: _ctx.name,
        type: _ctx.type,
        placeholder: _ctx.placeholder,
        size: _ctx.size,
        class: _normalizeClass(["the-input", { 'has-error': !!_ctx.errorMessage }]),
        onInput: _ctx.handleChange
      }, null, 8, ["id", "value", "name", "type", "placeholder", "size", "class", "onInput"]),
      _withDirectives(_createElementVNode("p", {
        class: _normalizeClass(["help-message absolute mt-1", { 'has-error-message': !!_ctx.errorMessage }])
      }, _toDisplayString(_ctx.errorMessage), 3), [
        [_vShow, _ctx.errorMessage]
      ])
    ])
  ], 2))
}